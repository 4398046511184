.App {
  background-color: #485fbf;
  height: 100vh;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='80' height='80' viewBox='0 0 80 80'%3E%3Cg fill='%23081d56' fill-opacity='0.34'%3E%3Cpath fill-rule='evenodd' d='M11 0l5 20H6l5-20zm42 31a3 3 0 1 0 0-6 3 3 0 0 0 0 6zM0 72h40v4H0v-4zm0-8h31v4H0v-4zm20-16h20v4H20v-4zM0 56h40v4H0v-4zm63-25a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm10 0a3 3 0 1 0 0-6 3 3 0 0 0 0 6zM53 41a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm10 0a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm10 0a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm-30 0a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm-28-8a5 5 0 0 0-10 0h10zm10 0a5 5 0 0 1-10 0h10zM56 5a5 5 0 0 0-10 0h10zm10 0a5 5 0 0 1-10 0h10zm-3 46a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm10 0a3 3 0 1 0 0-6 3 3 0 0 0 0 6zM21 0l5 20H16l5-20zm43 64v-4h-4v4h-4v4h4v4h4v-4h4v-4h-4zM36 13h4v4h-4v-4zm4 4h4v4h-4v-4zm-4 4h4v4h-4v-4zm8-8h4v4h-4v-4z'/%3E%3C/g%3E%3C/svg%3E");
}

.detail-bg {
  background: #23506a;
  height: 18rem;
}

.detail-top {
  background: #4369b2;
  height: 5rem;
}

.list-group-item {
  font-size: 20px;
}

.list-group-item:nth-child(even) {
  background: #4369b2;
}

.list-group-item:nth-child(odd) {
  background: #7ba0e7;
}

body {
  font-family: "Gill Sans MT", sans-serif;
}

.homepage-top {
  background: #4369b2;
}

.homepage-top-left {
  background-image: url(./assets/rain.png);
  background-size: 30% 80%;
  background-repeat: no-repeat;
  background-position: center center;
}

.overlay {
  background: rgba(67, 105, 178, 0.9);
  height: 10rem;
}

.overlay-right {
  background: rgba(58, 100, 179, 0.9);
  height: 10rem;
}

.homepage-nav {
  background: #7ba0e7;
}

.stats {
  background: #2f4d87;
}

.search-field {
  outline: none;
}

.city {
  background-image: url(./assets/rain.png);
  background-size: 20% 30%;
  background-repeat: no-repeat;
  background-position: center center;
}

.card:nth-child(odd) {
  background: rgba(67, 105, 178, 0.9);
}
